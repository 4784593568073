.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  /* justify-content: center; */
  width: 55em;
  max-width: 900px;
  padding: 1.25em;
  border: none;
  border-radius: 1.25em;
  background: #f4f7fc;
  font-family: inherit;
  font-size: 1rem;
  outline: 0;
  padding: 30px;
}

.title {
  text-align: left;
  padding: 16px 0;
  margin: 0;
}

.button {
  margin-top: 10px !important;
  color: #fff !important;
  background-color: #00b46e !important;
  border-color: #00b46e !important;
  padding: 10px !important;
}

.product_name {
  text-transform: capitalize;
  color: #00b46e !important;
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.product_name:hover {
  color: #00683f !important;
}

.ovfy {
  max-height: 820px;
  overflow-y: scroll;
}

.ovfy[class*='MuiTableContainer-root'] {
  max-height: 230px;
}
