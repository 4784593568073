.container {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.content {
  padding: 19px 0px;
}
.text_err_notification {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #80191f;
}
.text_total_style {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #636363;
}
.img_container {
  border: 1px solid #dcdbdb;
  border-radius: 7px;
  height: 86px;
  width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_product_name {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
.text_product_quantity {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #09884d;
}
.text_product_err {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #d01010;
}
.scroll_y {
  overflow-y: scroll;
}
