.feedback_order {
  width: 100%;
  max-width: 700px;
  max-height: calc(100vh - 50px);
  background: #f4f7fc;
  overflow: auto;
  outline: 0;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .feedback_order {
    height: 100%;
  }
}

.container {
  padding: 15px;
}

.title {
  background: rgba(0, 175, 107, 0.8784313725490196);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  height: 64px;
}

.statusTitle {
  margin-left: 4px;
}

.label {
  color: #000000;
  font-weight: 500;
}

.text_body {
  font-size: 15px;
  font-weight: 500;
  padding-left: 5px !important;
}

.value {
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.feedbackWrap {
  border: 1px solid #b5b7bb;
  border-radius: 4px;
  width: 100%;
  padding: 14px 10px;
  overflow-wrap: break-word;
  background-color: #ffffff;
}

.feedbackConent {
  padding-bottom: 10px;
  font-size: 14px;
  color: #404040;
  white-space: pre-line;
}

.feedbackImg {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
}

.button_find {
  padding: 3px !important;
}

.text_red {
  display: block;
  color: red;
}

.button_send {
  /* color: ; */
  background: #f4f7fc !important;
  padding: 25px;
}

.reason_select {
  height: 2.4em !important;
}

.reason_select [class*='MuiInputBase-input'] {
  padding: 0 0 0 15px !important;
  height: 100%;
}

.container [class*='input__info'] {
  height: 50px !important;
}

.textarea,
.imagesField {
  margin-top: 10px !important;
}

.info_group {
  margin-bottom: 20px !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.rug-handle-icon {
  width: 70px;
}

.customText {
  margin-bottom: 5px;
}

.imagesField [class*='info_form_grid'] {
  margin-bottom: 5px;
}

.card {
  position: relative;
  width: 70%;
  height: 30vh;
  border-radius: 10px;
  background-image: linear-gradient(to right top, #0089ba, #0078c0, #0064c3, #004ec1, #1730b5);
  border: 1px solid black;
}

.bank_name {
  position: absolute;
  left: 5%;
  top: 5%;
  font-size: 45px;
  font-weight: 700;
  color: white;
}

.bank_code {
  position: absolute;
  left: 20%;
  top: 35%;
  font-size: 25px;
  font-weight: 700;
  color: white;
}

.info_bank_title {
  display: block;
  text-align: center;
  font-family: ggsr;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: #000000;
}

.content {
  margin-top: 20px;
}

.ticket_status_chip {
  color: white !important;
  font-weight: 550;
  margin-left: 10px;
}

.ticket_status_chip [class*='MuiChip-icon'] {
  color: white;
}

.ticket_status_chip [class*='MuiSvgIcon-root'] {
  font-size: 1.15rem;
}

.reason_button[class*='MuiButton-root'] {
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-left: 8px;
  margin-bottom: 8px;
}

.ticket_img_container {
  width: 670px;
  padding: 17px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  /* background-color: black; */
}

.ticket_img {
  /* margin-left: 10px; */
  /* padding: 5px; */
  border: 1px solid rgba(0, 175, 107, 0.8784313725490196);
  border-radius: 5px;
  height: 90px;
}

.pop_over {
  /* position: fixed !important; */
  top: 50%;
  left: 50%;
}

.text_field [class*='Mui-disabled'] {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.close_button {
  position: absolute !important;
  top: 0;
  right: 0;
  color: #fff;
}

.image_modal {
  position: relative;
}

.buttonSend {
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.buttonSend .SendFeedback {
  background-color: #00b46e;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.buttonSend button:hover {
  background-color: #1fad76;
}

.formfeedback {
  padding-left: 5px;
}

.formfeedback div {
  background-color: #ffffff;
}

.buttonCancel {
  background-color: #bdbdbd;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
}

.buttonCancel:hover {
  background-color: #afafaf !important;
}

.buttonActive {
  background-color: #00b46e;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.buttonInActive {
  background-color: rgb(249, 181, 20) !important;
  border: 1px solid rgb(249, 181, 20) !important;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-left: 8px;
}

.buttonActive:hover {
  background-color: #1fad76;
}

.feedbackReturn {
  margin-top: 14px;
}

.feedbackReturn_title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.nameTS {
  margin-left: 4px;
  margin-right: 10px;
  color: #00b46e;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.avatarUser {
  width: 22px;
  height: 22px;
  background-color: #717273;
}

.nameUser {
  margin-left: 4px;
  margin-right: 10px;
  color: #444444;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.date {
  margin-right: 4px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  font-size: 14px;
}

.accept {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.accept div {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 50%;
  overflow-wrap: break-word;
}

.btnAcept {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
