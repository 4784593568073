.deal_section {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

@media (max-width: 468px) {
  .deal_section {
    flex-direction: column;
  }
}

.root_process {
  height: 16px !important;
  border-radius: 9px;
}

.count_down {
  text-align: center !important;
}

.count_down,
.startDate {
  flex: 100%;
  font-family: googlesansregular;
  font-size: 10px;
  text-align: right;
  color: #d4323b;
}

.process_wrapper {
  position: relative;
  flex: 50% !important;
}

.process_content {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: googlesansmedium;
  color: #d4323b;
  font-size: 10px !important;
  width: 100%;
  text-align: center;
  opacity: 0.8;
}

.color_process_content {
  color: #e9e9e9 !important;
}

.bar_background {
  background-color: #fbc9be !important;
}

.blur_background {
  background-color: #e9e9e9 !important;
}

/* style for mobile v2  */
.process_wrapper_mv2 {
  padding: 0px !important;
}

.deal_section_mv2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.root_process_mv2 {
  height: 12px !important;
}
.countdown_timer_mv2 {
  padding: 0px !important;
}
.startDate_mv2 {
  padding: 0px !important;
}

.w_45 {
  width: 45px;
}
.w_90 {
  width: 90px;
}
.w_85 {
  width: 85px;
}
.w_100 {
  width: 100px;
}
.w_125 {
  width: 125px;
}
.count_down_container {
  flex: 50% 1;
}
