.tab {
    min-width: unset!important;
}

.indicator {
    background-color: #f9b514!important;
    height: 5px!important;
}
.mobileIndicator{
    background-color: #00B46E!important;
    height: 5px!important;
    border-radius: 30px;
}

.content {
    padding-left: 30px;
}
.tabs button{
    padding: 0!important;
    margin: 6px 12px;
}

@media (max-width: 767px) {
    .tab_panel{
        padding: 15px !important;
    }
    .content{
        padding-left: 0;
    }
}