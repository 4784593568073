.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  outline: 0;
}

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px !important;
  line-height: 1.5;
  border-radius: 50px !important;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.outlined_btn {
  color: rgb(0, 180, 110) !important;
  border: 1px solid rgb(0, 180, 110) !important;
  margin: 0.5rem !important;
  padding: 6px 1.5rem !important;
}

.brown_btn {
  color: #fff !important;
  background: #facea8 !important;
  border: 1px solid #facea8 !important;
  margin: 0.5rem !important;
  padding: 6px 1.5rem !important;
}

.brown_btn:hover {
  background: #fff !important;
  color: #facea8 !important;
}

.fill_btn {
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 180, 110) !important;
  background-color: rgb(0, 180, 110) !important;
  margin: 0.5rem !important;
  padding: 6px 1.5rem !important;
}

.fill_btn:hover {
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(0, 180, 110) !important;
  color: rgb(0, 180, 110) !important;
  margin: 0.5rem !important;
  padding: 6px 1.5rem !important;
}

.warning_icon {
  display: flex;
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
  border-color: #facea8;
  color: #f8bb86;
}

.text_icon {
  display: flex;
  align-items: center;
  font-size: 3.75em !important;
}

.modal_title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em !important;
  padding: 0;
  color: #595959;
  font-size: 1.875em !important;
  font-weight: 600 !important;
  text-align: center;
  text-transform: none;
  font-family: ggsm;
}

.modal_content {
  font-family: ggsr;
  color: #545454 !important;
  font-size: 1.125em !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: center;
}
.font_gg {
  font-family: ggsm !important;
}
