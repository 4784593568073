.img_container {
  border: 1px solid #dcdbdb;
  border-radius: 7px;
  height: 86px;
  width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_product_name {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
.text_product_name:hover {
  color: #00b46e !important;
  transition: color 0.2s;
}
.text_product_quantity {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #09884d;
}
.text_product_err {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #d01010;
}
.link {
  display: inline !important;
  padding: 0px !important;
}
