.modal_wrapper {
  width: 502px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 20px;
}
.modal_mobile_wrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 10px;
}
.warning_icon {
  display: flex;
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
  border-color: #facea8;
  color: #f8bb86;
}
.text_icon {
  display: flex;
  align-items: center;
  font-size: 3.75em !important;
}
.btn_cancel {
  width: 188px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #219653;
  border-radius: 30px;
}
.btn_cancel span {
  text-transform: uppercase;
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #219653;
}
.btn_ok {
  width: 188px;
  padding: 12px;
  background: #219653;
  border-radius: 30px;
}
.btn_ok:hover {
  background: #055628;
}
.btn_ok span {
  text-transform: uppercase;
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.text_title {
  font-family: ggsr;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #000000;
}
.btn_ok_mobile {
  padding: 4px;
  width: 190px;
  border: 1px solid #09884d;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  filter: invert(38%) sepia(100%) saturate(6214%) hue-rotate(150deg) brightness(96%) contrast(93%) !important;
}
.btn_ok_mobile span {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #09884d;
}
.text_top_mobile {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #d01010;
}
.btn_ic_close {
  position: absolute;
  top: -14px;
  right: -11px;
}
.btn_ic_close img {
  filter: invert(38%) sepia(100%) saturate(6214%) hue-rotate(150deg) brightness(96%) contrast(93%) !important;
}
.icon_close {
  color: #c0c0c0;
  size: 12px;
}
