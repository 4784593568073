.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 96px rgba(0, 0, 0, 0.15);
  max-height: calc(100vh - 100px);
  width: 60%;
  font-size: 1rem;
  outline: none;
}

.header {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.closeIcon {
  position: absolute;
  right: 1rem;
  color: #fff;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.closeIcon:hover {
  color: #000;
}

.fixed_width_wrapper {
  width: 100%;
  max-width: 35rem;
}

.modal_title {
  margin: 16px 8px;
  padding-left: 5px;
  font-family: googlesansmedium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #fff;
}

.children {
  padding: 0px 10px;
  min-height: 100%;
  height: 250px;
  /* overflow: hidden; */
  word-wrap: break-word;
  overflow-y: scroll;
}

.gift_icon {
  padding-left: 16px;
}

.wrapLoading {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 265px;
  max-height: 265px;
}

.containerPromoDiablog {
  padding: 0px 1px 6px 6px;

}

.wrapImg {
  border-radius: 50%;
  background: rgb(255, 255, 255);
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  margin-left: 20px;
  display: flex;
}

.header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

@media (min-width: 800px) {
  .btn_container {
    margin: 16px 0 0 0;
  }

  .confirm_modal_wrap {
    width: 48%;
  }
}


@media (max-width: 968px) {
  .btn_container {
    margin: 16px 0 0 0;
  }

  .confirm_modal_wrap {
    width: 80%;
  }
}