.linkShop {
  border: 1px solid #09884d;
  border-radius: 30px;
  color: #09884d;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 7px 10px;
  display: flex;
  align-items: center;
}

.btnLinkCustomShop,
.linkCustomShop {
  width: 100%;
}

.linkCustomShop {
  margin-top: 20px;
  padding: 7px 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #025bac;
}

.flagshipTag {
  position: absolute;
  top: -21px;
  left: -16px;
  padding: 2px 8px;
  font-size: 10px;
  color: #fff;
  border-radius: 8px 0px;
  font-family: ggsr;
  font-size: 10px !important;
}

.linkShop [class*='MuiButton-label'] {
  max-height: 20px;
}

.avatar_name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 52px !important;
  height: 52px !important;
}

.avatar img {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.name {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 23px;
  text-transform: capitalize;
  color: #000000;
}

.subName,
.subNameBottom {
  font-family: ggsr;
  font-size: 14px;
  line-height: 18px;
  color: #797979;
  margin-top: 2px;
}

.subNameBottom {
  margin-top: 12px;
  text-align: left;
}

.btnLinkShop {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.cooperation {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.sellerIcon {
  margin-right: 5px;
}

.years {
  background: #0e1983;
  border-radius: 50%;
  width: 38px;
  text-align: center;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.years [class*='yearsNumber'] {
  color: #fff;
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  text-transform: capitalize;
}

.cooperationText {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #797979;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #797979;
}

.row span {
  font-size: 16px !important;
}

.bottom {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 12px;
  line-height: 18px;
}

.bottom1 {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  margin: 12px 0px;

  line-height: 18px;
}

@media (max-width: 959px) {
  .linkCustomShop {
    max-width: 200px;
    margin: 10px auto;
  }

  .subNameBottom {
    text-align: center;
  }
}

/* using for mobile v2 */
.avatar_mv2 {
  width: 40px !important;
  height: 40px !important;
}
.text_tf_none {
  text-transform: none !important;
}
