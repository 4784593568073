.paperroot {
  margin-bottom: 24px;
  border-radius: 10px !important;
}

.root {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  border-radius: 10px !important;
  margin-bottom: 24px;
}

.title {
  color: black;
  margin: 0;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500 !important;
}

.margin_none {
  margin: 0 !important;
}

.checkbox [class*='PrivateSwitchBase-root-23'] {
  /* padding: 5px !important; */
}

.root [class*='MuiAccordion-root:before'] {
  display: none;
}

.root [class*='MuiAccordionSummary-root'] {
  padding: 0 !important;
  min-height: auto;
}

.root[class*='MuiPaper-elevation4'] {
  box-shadow: none !important;
}

.root[class*='MuiAccordion-root']:before {
  display: none;
}

.root [class*='MuiAccordionSummary-content'] {
  margin: 0;
}

.root [class*='MuiAccordionSummary-root']:hover:not(.Mui-disabled) {
  cursor: default;
}

.text_area {
  display: block;
  width: 100%;
  height: 111px !important;
  padding: 0.475rem 0.75rem;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: vertical;
}

.checkbox {
  color: #00b46e !important;
}

.red_bill {
  color: #00b46e !important;
}

.text_area:focus {
  color: #495057;
  background-color: #fff;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 180, 110, 0.25);
}

.fw500 {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #212529 !important;
}

.root {
  margin: 0 !important;
}

.root [class*='MuiButtonBase-root'] {
  align-items: baseline !important;
}

.icon {
  margin-right: 10px;
  color: #00b46e !important;
}

.helper_text {
  margin-top: 20px;
}

.full_width {
  width: 100%;
}

.notifi_list li {
  font-size: 14px;
  font-weight: 500;
}

.notifi_list li::marker {
  font-size: .7rem;
}

@media (max-width: 767px) {
  .root {
    padding: 15px;
  }

  .title,
  .text_muted {
    /* display: block; */
  }

  .text_muted .text {
    padding-left: 5px;
  }

  .grid_fix [class*='info_form_grid']:last-child {
    padding-top: 0 !important;
  }

  .save_info_cb {
    margin-top: 20px;
  }
}