.text_area {
  display: block;
  width: 100%;
  height: 111px !important;
  padding: 0.475rem 0.75rem;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important    ;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: vertical;
}

.text_area:focus {
  color: #495057;
  background-color: #fff;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 180, 110, 0.25);
}
