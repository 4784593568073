.root_input [class*='MuiInputBase-input'] {
  font-weight: 500 !important;
  font-size: 14px !important;
  background-color: white;
  /* border-radius: 2rem !important; */
  border: 1px solid #ced4da !important;
  width: 100% !important;
  padding: 20px !important;
  padding-left: 47px !important;
}

.input {
  text-align: left;
  color: #495057;
  font-size: 12px;
  width: 100% !important;
}

.focus {
  border: 1px solid #35ffb0 !important;
  box-shadow: 0 0 0 0.2rem rgb(0 180 110 / 25%) !important;
  color: #495057 !important;
  outline: 0 !important;
  border-color: transparent !important;
}

.search_wrap {
  /* max-width: 470px; */
  width: 100%;
  position: relative;
  z-index: 610;
  padding-top: 15px;
}

.search_wrap [class*='MuiInput-underline']::after {
  border: none !important;
}

.search_wrap [class*='MuiInput-underline']::before {
  border: none !important;
}

.search_wrap [class*='MuiInputAdornment-positionStart'] {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  margin: 0px;
}

.root_input [class*='Mui-focused'] [class*='MuiInputBase-input'] {
  border: none !important;
}
@media (max-width: 767px) {
  .root_input {
    width: 100%;
  }
  .root_input .focus {
    border-radius: 0 !important;
  }
  .root_input [class*='MuiInputBase-input'] {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0 !important;
    padding-left: 7px !important;
  }
  .root_input [class*='MuiInputAdornment'] {
    display: none;
  }
}
