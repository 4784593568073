.searchDropdown {
  position: absolute;
  width: 100%;
  min-width: 25em;
  max-height: 20em;
  margin-top: 0.5rem !important;
  overflow-y: scroll;
  z-index: 610;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
}

.searchResults {
  padding: 16px !important;
  border-bottom: 1px solid rgba(195, 204, 220, 0.4);
  display: block !important;
  color: #343a40;
  transition: color 0.2s, background-color 0.2s;
  font-size: 16px !important;
  word-break: break-word;
}

.searchResults:hover {
  background-color: #f8f9fa;
  color: #00b46e;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s;
}

.searchResults:hover a {
  color: #00b46e !important;
}

.textPrimary {
  color: #00b46e !important;
  font-weight: 500;
}
