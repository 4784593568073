.product_content_column {
  margin-bottom: 0 !important;
}

.product_name {
  font-family: ggsm !important;
  display: inline !important;
  align-items: flex-start;
  font-size: 14px !important;
  line-height: 1.2 !important;
  color: #343a40;
  text-transform: capitalize;
  transition: color 0.2s;
  font-weight: 500 !important;
  cursor: pointer;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0 !important;
}

/* TODO: @Tien tạm off */
.flex_wrap {
  /* width: 330px; */
  flex-wrap: wrap;
}

.product_name:not(.giftlink):hover {
  color: #00b46e;
  transition: color 0.2s;
}

.link {
  display: inline !important;
  padding: 5px 0 5px;
}

.product_type {
  flex: 50%;
  font-size: 12.8px !important;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 6px;
}

.align_center {
  align-self: center;
}

.muted {
  color: #919aa3 !important;
}

.product_category {
  font-size: 12px !important;
  font-family: googlesansregular;
}

.product_category a {
  text-decoration: none;
  color: #495057;
  transition: color 0.2s;
}

.product_category a:not(:last-child) {
  margin-right: 4px;
}

.product_category a:hover {
  color: #00b46e;
}

.product_tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 6px;
}

.product_tags_column {
  margin-bottom: 0 !important;
}

.product_tags>* {
  margin-bottom: 5px;
  margin-right: 5px;
}

.product_title_column_wrap {
  flex-direction: row !important;
  align-items: center;
}

.product_title {
  flex: 90%;
  text-align: left;
  padding-right: 10px !important;
  line-height: 1 !important;
}

.product_type {
  text-align: left;
}

.h40 {
  height: 40px;
}

.product_content {
  padding: 0 !important;
}

.discount_flag {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  padding: 2.6px 7.9px;
  margin-bottom: 5px;
  white-space: normal;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #dc3545;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  margin-right: 5px;
}

.discount_flag:hover {
  opacity: 0.5;
}

.discount_flag span {
  line-height: 18px;
}

.boxPerDay {
  margin-top: 8px;
}

/* style for mobilev2 */
.product_name_mv2 {
  font-size: 12px !important;
}

.product_tag_mv2 {
  margin: 0px !important;
}

.linkMobile {
  display: inline !important;
  margin-bottom: 4px;
  padding: 5px 0;
}

.mobileProduct_type {
  flex: 50%;
  font-size: 12.8px !important;
  font-weight: 400 !important;
  text-align: left;
  margin: 6px 0;
}

.giftPromo {
  font-family: ggsm !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #D55D2A;
}

.prioritizedPromo {
  display: none;
}

@media (max-width: 767px) {
  .product_title_wrap {
    margin-bottom: 0 !important;
  }

  .product_content_column {
    padding: 0 !important;
  }

  .product_title_wrap .product_type {
    text-align: left;
  }

  .product_name {
    margin-bottom: 0 !important;
  }

  .product_title {
    height: auto;
    padding-right: 0 !important;
    width: 98%;
  }

  .product_tags {
    margin: 10px 0 8px !important;
  }

  .product_content {
    flex: 30% 1 !important;
  }
}

@media (min-width: 768px) {
  .product_content_column {
    max-width: 520px;
    width: 100%;
  }

  .product_content {
    margin-bottom: 0;
  }

  .product_title_wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .product_title {
    margin-bottom: 5px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .product_content_column {
    max-width: 450px;
  }

  .product_action {
    width: 90px;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .product_title {
    flex: 45%;
    max-width: 400px;
  }

  .product_content {
    max-width: 400px;
  }

  .product_title_wrap {
    flex-wrap: wrap;
  }

  .flex_wrap {
    width: 320px;
    flex-wrap: wrap;
  }

  .product_tags {
    max-width: 315px;
  }
}

.product_tags>a [class*='tag_container'] {
  margin-bottom: 5px;
  /* margin-right: 5px; */
}

.flex_70 {
  flex: 70% !important;
}

.flex_40 {
  flex: 40% !important;
}

.mw_440 {
  max-width: 440px;
}

.product_name_mv2_horizontal {
  font-size: 14px !important;
}