.warpper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}
.manufactureInfo {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
.manufactureInfo_mv2 {
  font-size: 14px !important;
}
.manufactureInfoLink {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1983;
}