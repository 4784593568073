.date_tag_container {
  height: fit-content;
  padding: 4px 8px;
}

.tag_container {
  border-radius: 4px;
  border: solid 1px;
  font-weight: 500;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  line-height: 15px;
  vertical-align: top;
  cursor: pointer;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
}

.tag_containerv2 {
  border-radius: 4px;
  border: solid 1px;
  font-weight: 500;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  line-height: 15px;
  vertical-align: top;
  cursor: pointer;
  padding: 4px 8px;
}



.normal_tag_container {
  padding: 4px 8px;
}

.icon_tag_container {
  padding: 2px;
  border-radius: 4px;
  border: solid 1px;
  font-weight: 500;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  line-height: 15px;
  margin: 0;
  vertical-align: top;
  cursor: pointer;
  padding: 4px 8px;
}

.icon_tag_container svg {
  width: 15px !important;
  height: 14px !important;
}

.tag_container:hover {
  opacity: 0.5;
}

.nameTag {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  margin-left: 2px;
}

.nameTagMobileV2 {
  font-size: 9px;
  line-height: 10px;
}

.tag_container_mobilev2 {
  height: 16px;
  line-height: unset;
  padding: 0px 3px
}