.card_wrapper {
  padding: 0 10px 10px 10px !important;
}

.sale_price {
  color: #868585;
  font-family: googlesansmedium !important;
  font-size: 14px;
  line-height: 20px;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.displayPrice_wrapper {
  margin: 0;
  padding: 4px 0;
}

.wishlist_price_wrapper {
  margin: 0;
}

.display_wishlist_price_wrapper {
  margin: 0px;
  padding: 0px;
}

.wrap {
  flex-flow: wrap;
}

.display_price {
  font-family: googlesansmedium !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #292929;
}

.will_buy_text {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 2 !important;
  font-family: googlesansregular;
  color: #dc3545;
}

.discount_number {
  letter-spacing: -0.1px;
  color: #ffffff;
  font-family: googlesansmedium;
  font-weight: 500;
  font-size: 12px;
  height: 100%;
  background: #d5323b;
  padding: 0 4px;
  border-radius: 4px;
}

.discount_number span {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.up {
  color: #dc3545;
}

.down {
  color: #00b46e;
}

.text_danger {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #dc3545;
  font-family: googlesansregular;
}

.text_desc {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #636363;
  font-family: googlesansregular;
}

.text_padding {
  padding: 0 0 10px 0;
}

.text_center {
  text-align: center;
}

.product_action {
  padding: 0;
}

.product_action_mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 5px;
}

.btn_del_m_wrapper {
  padding: 0px;
  position: absolute;
  top: 5px;
  right: 6px;
}

.input_container {
  display: flex;
  justify-content: center;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 30px;
  height: 30px;
  flex: 0 0 100%;
  font-family: ggsm;
}

.input_wishlist {
  display: flex;
  justify-content: center;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 30px;
  height: 30px;
  flex: 0 0 100%;
  font-family: ggsm;
  max-width: 164px;
}

.gift_label {
  color: #6f789c;
  font-weight: 500;
}

.border_focus_left {
  border-top: 1px solid #2cd888 !important;
  border-left: 1px solid #2cd888 !important;
  border-bottom: 1px solid #2cd888 !important;
  border-radius: 30px 0px 0px 30px;
}

.border_focus_input {
  border-top: 1px solid #2cd888 !important;
  border-bottom: 1px solid #2cd888 !important;
}

.border_focus_right {
  border-top: 1px solid #2cd888 !important;
  border-right: 1px solid #2cd888 !important;
  border-bottom: 1px solid #2cd888 !important;
  border-radius: 0px 30px 30px 0px;
}

@media (min-width: 768px) and (max-width: 960px) {
  [class*='cartpage'] .product_action_column {
    flex: 47%;
    max-width: 47%;
  }

  .price_wrapper_column {
    flex: 20%;
    max-width: 20%;
    margin-right: 5px !important;
  }
}

@media (max-width: 767px) {
  .remove_icon {
    max-width: 100%;
    position: absolute !important;
    right: 5px;
    top: 5px;
    padding: 5px !important;
  }

  .text_danger_column {
    position: static;
    text-align: right;
    margin-top: 10px !important;
  }

  .price_wrapper {
    margin-bottom: 0 !important;
    text-align: left;
    min-width: 100px;
  }

  .text_change_price {
    position: static;
    text-align: right;
    margin-top: 0 !important;
  }

  .deal_price {
    text-align: left;
  }

  .has_item input {
    color: #343a40;
  }

  .product_action {
    min-width: 60px;
  }

  .display_price {
    font-size: 16px;
  }
}

/* style for mobile v2 */

.displayPrice_wrapper_mv2 {
  display: flex;
  align-items: center;
  padding: 0px;
}

.display_price_mv2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
}

.sale_price_mv2 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: #797979;
}

.card_wishlist_wrapper {
  display: flex;
  padding: 8px 0px 8px 0px !important;
  flex: 20%;
}

.card_wishlist_mobile_wrapper {
  display: flex;
  padding: 0px 0px 0px 10px !important;
  margin-top: 6px;
  margin-bottom: 10px;
}

.wishlist_input_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.wishlist_price {
  margin-top: 6px;
  margin-right: 10px;
  width: 95px;
}

.wishlist_price p {
  font-family: ggsm !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000;
}

.discount_percent {
  background-color: #d4323b;
  font-size: 10px;
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
}

.text_desc_wishlist {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
  position: absolute;
  right: 63px;
  top: 10px;
}

.text_desc_wishlist_mobile {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #797979;
  text-align: right;
}

.text_quantity_wishlist {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
  position: absolute;
  right: 63px;
  bottom: 10px;
}

.text_quantity_wishlist_mobile {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #797979;
  text-align: right;
}

.wishlist_err_wrapper {
  flex: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
}

.wishlist_err_wrapper_mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 135px;
}

.remove_icon_mobile_err {
  padding: 0px !important;
}

.remove_icon_mobile {
  padding: 0px !important;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.fs_12 {
  font-size: 12px !important;
}

.discount_percent_v2 {
  position: absolute;
  width: 39px;
  height: 20px;
  left: 0px;
  top: 0px;
  background: #d4323b;
  border-radius: 7px 0px;
  z-index: 1;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.p_0 {
  padding: 0px !important;
}

.ml_10 {
  margin-left: 10px;
}

.pt_55 {
  padding-top: 5.5px !important;
}

.mt_5 {
  margin-top: 5px;
}

.mt_15 {
  margin-top: 15px;
}

.mh_28 {
  max-height: 28px !important;
}

.hidden {
  display: none;
}

.btn_del_text {
  font-family: ggsr;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #595959;
}

@media (min-width: 955px) and (max-width: 1050px) {
  .wishlist_input_container {
    justify-content: flex-end !important;
  }

  .wishlist_price {
    width: 100px !important;
    margin-right: 15px !important;
  }
}

.card_wrapper_mv2 {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.contractLabel {
  background-color: rgb(10, 10, 78);
  color: #fff;
  border-radius: 6px;
  padding: 3px 5px;
  width: -webkit-fit-content;
  width: fit-content;
  margin-bottom: 3px;
}
