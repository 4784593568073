.card {
  border: 2px solid rgb(183, 199, 193);
  height: 170px;
}

.card [class*='MuiCardContent-root'] {
  height: 100%;
  padding-top: 0px !important;
}

.add_icon_row [class*='MuiSvgIcon-root'] {
  font-size: 50px;
}

.add_icon_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.add_icon_row {
  text-align: center;
}
