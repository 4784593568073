.desc {
  font-size: 16px;
  margin: 0 0 2rem 0;
  max-width: 450px;
  font-family: googlesansregular;
}

.error {
  color: tomato;
  padding: 0rem 0 1rem 0;
}

.input {
  margin: 0 1rem 1rem 0rem !important;
  font-family: googlesansregular;
}

.input fieldset {
  border: 1px solid #dbdbdb;
  border-radius: 8px !important;
}

.input.disabled {
  background-color: #f4f7fc !important;
}

.timing_wrapper {
  padding: 0 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timing {
  border: 3px solid #3cb56b;
  border-radius: 50%;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.center {
  display: flex;
  justify-content: center;
}

.btn {
  color: white;
  font-weight: 500;
  font-family: googlesansmedium;
  text-transform: uppercase;
  background: #0cba69;
  border-radius: 30px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  padding: 8px 50px !important;
  margin: 1rem;
}

.confirm_modal_wrap {
  max-width: 100%;
  padding: 1.25em;
  font-family: googlesansregular;
  font-size: 1rem;
  outline: 0;
}

.warning_icon {
  display: flex;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
  border-color: #00b46e;
  color: #00b46e;
}

.text_icon {
  display: flex;
  align-items: center;
  font-size: 3.75em !important;
}

.modal_title {
  font-family: googlesansmedium;
  max-width: 100%;
  margin: 0 0 0.2rem 0 !important;
  padding: 0;
  color: #292929;
  font-size: 1.6em !important;
  text-align: center;
  text-transform: none;
}
.mb_10 {
  margin-bottom: 10px;
}
