.default_tag {
  color: rgb(0, 180, 110);
  font-weight: bold;
}

.selected_tag {
  color: rgb(0, 180, 110);
  font-weight: bold;
}

.card {
  border: 2px solid rgb(183, 199, 193);
  height: 170px;
  position: relative;
}

.card [class*='MuiCardContent-root'] {
  /* height: 100%; */
}
.card [class*='MuiCardHeader-title'] {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 500;
  max-width: 319px;
}

.card [class*='MuiCardHeader-subheader'] {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
}

.card [class*='MuiCardHeader-subheader']::before {
  content: 'Số điện thoại: ';
  font-weight: 500;
}

.card [class*='MuiCardContent-root'] {
  padding-top: 0px !important;
}

.card [class*='MuiCardHeader-root'] {
  padding-bottom: 0px !important;
}

.card_selected {
  border: 3px solid rgb(0, 180, 110);
}

.action_button {
  font-weight: 400 !important;
  padding: 3px 7px !important;
  margin-right: 5px !important;
  box-shadow: none !important;
}

.important_action_button {
  background: rgb(0, 180, 110) !important;
  color: white !important;
}

.group_button {
  position: absolute;
  bottom: 12px;
}

.defaull_button {
  background: #f9b514 !important;
}

.ellipsis_1_line {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis_2_line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media (max-width: 1072px) {
  .card [class*='MuiCardHeader-title'] {
    font-size: 1rem !important;
    max-width: 200px;
  }
}
