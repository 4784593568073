.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 2rem 3rem;
  border: none;
  border-radius: 10px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  outline: 0;
  box-shadow: 0px 2px 96px rgba(0, 0, 0, 0.15);
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.closeIcon {
  position: absolute;
  top: 2.75rem;
  right: 3rem;
  color: #ababab;
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.closeIcon:hover {
  color: #000;
}

.fixed_width_wrapper {
  width: 100%;
  max-width: 35rem;
}

.modal_title {
  margin: 16px 0;
  padding: 0;
  font-family: googlesansmedium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #000000;
}

.modal_content {
  margin: 25px 0;
  /* color: #636363 !important;
  font-size: 1.125em !important;
  font-weight: 400 !important;
  line-height: normal !important;
  font-weight: normal; */
  font-family: googlesansregular;
}

.children>* {
  font-family: googlesansregular;
}

.hr {
  border: 0.5px solid #e3e3e3;
  margin: 20px 0;
}

/* YES NO BUTTON */
.yes_btn {
  background: #0cba69;
  color: #ffffff;
  text-transform: uppercase;
}

.yes_btn:hover {
  background: #0ba55d;
}

.yes_btn span {
  margin: 0 18px;
}

.btn_container {
  display: flex;
  text-align: center;
  margin: 16px 30px 0 30px;
  justify-content: center;
  align-items: center;
  font-family: googlesansmedium;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media (max-width: 576px) {
  .btn_container {
    margin: 16px 0 0 0
  }

  .confirm_modal_wrap {
    padding: 1.5rem 2.25rem;
  }
}

@media (max-width:576px) {
  .closeIcon {
    top: 2.1rem;
    right: 2rem;
  }
}


/* style using for mobile v2 */
.confirm_modal_wrap_mv2  {
  font-family: 'ggsm';
}
