.buttons {
  color: #0cba69;
  min-width: 50px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
}

.buttons span {
  font-weight: 700;
}

.buttons:hover {
  background: none;
}

.button_minus {
  border-right: 1px solid #dbdbdb;
}

.button_plus {
  border-left: 1px solid #dbdbdb;
  border-radius: 0;
}

.icon {
  font-size: 1.3rem !important;
  font-weight: bold;
}

.plus_disabled {
  color: #fff !important;
  border-radius: 30px;
  padding: 0 !important;
}

.button_root_minus {
  padding: 0px !important;
  border-radius: 0;
}

@media (max-width: 567px) {
  .buttons {
    min-width: 38px;
  }
}