.root {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  border-radius: 10px !important;
  margin-bottom: 24px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
  margin: 0;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500 !important;
}

.checkbox {
  color: #00b46e !important;
}

.text_muted {
  color: #919aa3 !important;
  font-style: italic !important;
  font-size: 12.8px;
  font-weight: normal !important;
}

.required {
  color: #dc3545 !important;
}

.fw500 {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #212529 !important;
}

.add_new_button {
  color: #00ab55 !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.full_width {
  width: 100%;
}

@media (max-width: 767px) {
  .root {
    padding: 15px;
  }
  .title,
  .text_muted {
    /* display: block; */
  }
  .text_muted .text {
    padding-left: 5px;
  }
  .grid_fix [class*='info_form_grid']:last-child {
    padding-top: 0 !important;
  }
  .save_info_cb {
    margin-top: 20px;
  }
}
