.confirm_modal_wrap {
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 24px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  outline: 0;
  box-shadow: 0px 2px 96px rgba(0, 0, 0, 0.15);
}

.fixed_width_wrapper {
  min-width: 30rem;
}

.warning_icon {
  display: flex;
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
  border-color: #facea8;
  color: #f8bb86;
}

.text_icon {
  display: flex;
  align-items: center;
  font-size: 3.75em !important;
}

.modal_title {
  margin: 16px 0;
  padding: 0;
  color: #202332;
  font-size: 1.25em !important;
  font-weight: 500 !important;
  text-transform: none;
  font-style: normal;
  font-family: googlesansmedium;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.modal_content {
  margin: 16px 0;
  color: #636363 !important;
  font-size: 1.125em !important;
  font-weight: 400 !important;
  line-height: normal !important;
  font-family: googlesansregular;
  font-weight: normal;
}

.children > * {
  font-family: googlesansregular;
}

.hr {
  border: 0.5px solid #e3e3e3;
  margin: 20px 0;
}

/*  INFO */
.infor_container {
  padding: 16px 8px;
}

.infor_mainTitle {
  color: #292929;
  font-family: googlesansmedium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
}

/* COMPANY INFO */
.infor_img {
  width: 72px;
  height: 72px;
}

.infor_name {
  color: #292929;
  font-family: googlesansmedium;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-transform: capitalize;
}

.infor_btnfollow {
  margin: 24px 0 !important;
  font-family: googlesansregular;
  font-size: 14px;
  line-height: 18px;
  color: #09884d;
  border: 1px solid #09884d;
}

.infor_btnfollow span:first-child span:first-child {
  padding: 2px 0 2px 4px;
}

.infor_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #636363;
  margin: 12px 0;
}

.infor_box p {
  font-size: 17px;
  line-height: 20px;
}

.infor_title {
  font-family: googlesansregular;
  font-style: normal;
  font-weight: normal;
}

.infor_content {
  color: #09884d;
  font-family: googlesansmedium;
}

.infor_titlePromotion {
  color: #292929;
  font-family: googlesansmedium;
  font-style: normal;
  text-transform: capitalize;
}

.infor_contentPromotion {
  color: #292929;
  font-family: googlesansregular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
}

/* YES NO BUTTON */
.yes_btn {
  background: #0cba69;
  color: #ffffff;
  /* width: 135px; */
}

.yes_btn:hover {
  background: #0cba69;
}

.no_btn {
  background: #ffffff;
  color: #000000;
  /* width: 135px; */
}

.yes_btn span,
.no_btn span {
  margin: 0 18px;
}
.btn_container {
  display: flex;
  text-align: center;
  margin: 16px 30px 0 30px;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  .btn_container {
    margin: 16px 0 0 0;
  }
  .btn_no,
  .btn_yes {
    width: 125px;
  }
}

@media (max-width: 480px) {
  .fixed_width_wrapper {
    min-width: 0;
  }
}