.timer {
  font-family: googlesansregular;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: 26px;
}
