.wrapper_modal {
  width: 940px;
  height: 651px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.modalBackdrop {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalImage {
  margin: 0 5px;
  height: auto !important;
  width: auto !important;
  border-radius: 20px;
  object-fit: contain;
  min-width: auto !important;
  max-width: 568px !important;
  max-height: 568px !important;
  padding: 20px !important;
  background-color: #fff;
}
@media (max-width: 767px) {
  .modalImage {
    padding: 20px !important;
    width: 100% !important;
  }
}
.modalImage:focus {
  outline: none;
}
.closeWrapper {
  width: 100%;
  padding: 19px;
  display: flex;
  justify-content: flex-end;
}
.imageMain {
  object-fit: contain;
}
.imageMainMobile {
  object-fit: contain;
  max-width: 80% !important;
  min-height: 80% !important;
}
.wrapperImg {
  background: #ffffff;
  border: 1px solid #dcdbdb;
  border-radius: 10px;
  margin-left: 59px;
  margin-bottom: 32px;
  width: 550px;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapperMobileImg {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #dcdbdb;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 300px;
  position: relative;
}
.imageNameText {
  color: #000000;
  font-family: ggsm;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}
.wrapper_thumbnail {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.scroll_vertical {
  height: 500px;
  overflow-y: scroll;
}
.wrapper_mobile_thumbnail {
  width: 300px;
}
.flex_center {
  width: 300px;
  display: flex;
  justify-content: center;
}
.thumbnail_image_wrapper {
  /* flex: 0 0 33.333333%; */
  width: 64px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #dcdbdb;
  padding: 8px !important;
}
.thumbnail_image_wrapper:hover {
  border: 1px solid #2cd888 !important;
}
.thumbnail_image_wrapper_mobile {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  padding: 6px !important;
  margin-right: 8px;
}
.thumbnail_selected {
  background: #edfdf6 !important;
  border: 1px solid #2cd888 !important;
}
.slider [class*='slick-prev'],
.slider [class*='slick-next'] {
  color: #afafaf;
  top: 20px;
}
.slider [class*='slick-prev']:hover,
.slider [class*='slick-next']:hover {
  color: #000;
}
.slider [class*='slick-prev'] {
  left: -4px;
}
.slider [class*='slick-next'] {
  right: -4px;
}
.slider {
  padding: 12px 16px;
  width: 100%;
}
.leftNavBtn {
  z-index: 10;
  position: absolute;
  left: -20px;
}
.rightNavBtn {
  z-index: 10;
  position: absolute;
  right: -20px;
}
