.root {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  border-radius: 10px !important;
  margin-bottom: 24px;
}

.title {
  color: black;
  margin: 0;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500 !important;
}

.checkbox_color {
  color: #00b46e !important;
}

.checkbox {
  padding: 5px !important;
}

.checkout_description {
  margin-top: 5px;
  margin-left: 24px;
  background-color: #f4f7fc !important;
  border: 1px solid #c3ccdc !important;
  border-radius: 6px !important;
}

.checkout_description p {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

.icon {
  color: #17a2b8 !important;
  margin-right: 8px;
}

.fw500 {
  font-weight: 500 !important;
}

.fw300 {
  font-weight: 300 !important;
  font-size: small;
}

@media (max-width: 767px) {
  .root {
    padding: 15px;
  }
}
