.input {
  width: 100%;
  margin: 8px 0;
}
.sendData {
  border-radius: 30px;
  padding: 12px 40px;
  font-size: 0.875rem;
  min-width: 176px;
  margin: 0.25em;

  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 180, 110) !important;
  border: 1px solid rgb(0, 180, 110) !important;
}

.textError {
  padding-top: 2px;
  color: red;
  font-family: googlesansregular;
  font-size: 12px;
  text-align: left;
}
.iconError {
  font-size: 16px;
  transform: translateY(3px);
  font-size: 16px;
  transform: translateY(3px);
}
.textRequired {
  color: red;
}
.slideLeft {
  animation: slide-left 300ms;
}
@keyframes slide-right {
  0% {
    opacity: 0.5;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
