.wrapper {
  display: flex;
  align-items: center;
}

.total {
  font-family: googlesansregular;
  font-size: 16px;
  line-height: 1.5;
  color: #797979;
  margin-left: auto;
  text-align: right;
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.5;
  font-family: googlesansmedium;
  text-transform: capitalize;
  color: #000000;
}

@media (max-width: 476px) {
  .title {
    font-size: 22px;
    line-height: 1.2;
  }

  .total {
    font-size: 14px;
    line-height: 1.1;
  }
}