.auth_modal_header {
  padding-bottom: 1rem;
}

.auth_modal_content {
  position: relative;
  background: #ffffff;
  outline: 0;
  padding: 20px 30px 10px 30px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow-y: auto;
}

.auth_modal_title {
  margin: 16px 0;
  padding: 0;
  color: #202332;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  font-style: normal;
  font-family: googlesansmedium;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.auth_modal_close {
  background-color: transparent;
  border: 0;
  color: #afafaf;
}

.auth_modal_body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

@media screen and (max-width: 767px) {
  .auth_modal_header {
    line-height: 32px;
  }

  .auth_modal_header div:last-child {
    display: flex;
    justify-content: center;
  }

  .auth_modal_content {
    width: 100%;
  }

  .auth_modal_body {
    padding: 0;
    height: calc(100% - 60px);
  }

  .auth_modal_body .form_control {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .auth_modal_content {
    padding: 15px;
  }

  .auth_modal_title {
    padding-left: 5px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .auth_modal_content {
    height: 100%;
  }
}