.form{
    margin: auto;
}
.title{
    text-align: center;
    color: #595959;
    font-size: 24px;
}
.bottom_text{
    color: #17a2b8 !important;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}
.error{
    color: tomato;
    margin: 5px 0;
}
.formInput{
    width: 100%;
}
.formInput [class*='MuiInputBase-root']{
    margin: 0 !important;
}
.input{
    margin: 0 !important;
}
.input.disabled{
  background-color: #f4f7fc !important;
}
.custom_field{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.right_c{
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remaining{
  border: 3px solid #3cb56b;
  border-radius: 50%;
  padding: 8px;
  width: 42px;
  height: 42px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.custom_field .input{
  width: 200px !important;
}
.confirm_modal_wrap {
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: 0.3125em;
    font-family: inherit;
    font-size: 1rem;
    outline: 0;
  }
  
  .btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 16px !important;
    line-height: 1.5;
    border-radius: 50px !important;
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
      border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  }
  
  .outlined_btn {
    color: rgb(0, 180, 110) !important;
    border: 1px solid rgb(0, 180, 110) !important;
    margin: 0.5rem !important;
    padding: 6px 1.5rem !important;
  }
  
  .brown_btn {
    color: #fff !important;
    background: #facea8 !important;
    border: 1px solid #facea8 !important;
    margin: 0.5rem !important;
    padding: 6px 1.5rem !important;
  }
  
  .brown_btn:hover {
    background: #fff !important;
    color: #facea8 !important;
  }
  
  .fill_btn {
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(0, 180, 110) !important;
    background-color: rgb(0, 180, 110) !important;
    margin: 0.5rem !important;
    padding: 6px 1.5rem !important;
  }
  
  .fill_btn:hover {
    background: rgb(255, 255, 255) !important;
    border: 1px solid rgb(0, 180, 110) !important;
    color: rgb(0, 180, 110) !important;
    margin: 0.5rem !important;
    padding: 6px 1.5rem !important;
  }
  
  .warning_icon {
    display: flex;
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
    border-color: #00b46e;
    color: #00b46e;
  }
  
  .text_icon {
    display: flex;
    align-items: center;
    font-size: 3.75em !important;
  }
  
  .modal_title {
    position: relative;
    max-width: 100%;
    margin: 0 0 0.4em !important;
    padding: 0;
    color: #595959;
    font-size: 1.875em !important;
    font-weight: 600 !important;
    text-align: center;
    text-transform: none;
  }
  
  .modal_content {
    color: #545454 !important;
    font-size: 1.125em !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-align: center;
  }
  .btngroup{
    text-align: center;
    margin: 16px auto;
  }
  .btngroup button{
    background: linear-gradient(102.04deg,#00b46e 0%,#9ac100 100%);
    color: white;
    height: 50px;
    box-shadow: 0px 3px 20px rgb(0 0 0 / 8%);
    border-radius: 50px;
    max-width: 200px;
    width: 100%;
  }
@media (max-width: 767px){
    .form{
        width: 100% !important;
    }
    .btngroup button{
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        margin-right: 0 !important;
    }
}